import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Carousel, Spinner } from 'react-bootstrap'
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';


function About() {

  const [about, setAbout] = useState()
  const [isLoading, setIsLoadings] = useState(true)
  const [metatage, setMetaTage] = useState()

  useEffect(() => {

    axios.get('https://admin.bstloffshore.com/api/getAboutUs')
      .then(resp => { setAbout(resp.data.data); setIsLoadings(false) })

    axios.get('https://admin.bstloffshore.com/api/getSeoMetaTagsBySlug/about-us')
      .then(response => { setMetaTage(response.data.data) })

  }, [0])



  return (
    <>
      {metatage &&
        <Helmet>
          <title>{metatage.seo.title}</title>
          <meta name="description" content={metatage.seo.description} />
          <meta name="keywords" content={metatage.seo.keywords} />
          <link rel="canonical" href={metatage.seo.canonical} />
          <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
        </Helmet>
      }
      {
        isLoading ? (
          <Container>
            <Row>
              <Col lg={12} className='text-center  spinner-css' >
                <Spinner />
              </Col>
            </Row>
          </Container>
        ) : (
          <div>

            {

              about &&
              <Container fluid >
                <Row>
                  <Carousel indicators={false} controls={false} className='About-carousel' >
                    {about.aboutus_sliders.map((item,index) => {
                      return (
                        <Carousel.Item key={index}  >
                          <img src={item.large_image_path + item.aboutus_slider_image}
                            className="d-block about-carousel-img"  
                            
                            alt='about-banner-img'
                            width={'100%'}
                            height={'100%'}
                          />
                        </Carousel.Item>
                      )
                    })}
                  </Carousel>
                </Row>
                <Container fluid  className='about-us-content'>

                  <Row >
                    <Col className='text-center mt-5' lg={5} md={5}>
                      <img src={about.aboutus_details.large_image_path + about.aboutus_details.left_side_image} className='ab6-image' width={'100%'}  alt="About-Image"
      loading="lazy" />
                    </Col>
                    <Col lg={6} md={7}  >
                      <h3 className='text-start mt-2 mb-4'>{about.aboutus_details.title}</h3>
                      {parse (  about.aboutus_details.description)}
                    </Col>

                  </Row>

                </Container>

              </Container>
            }

          </div>
        )}
    </>

  )
}

export default About
