import React from 'react'

function Privacy() {
    return (
        <div>

            <div class="container mb-5  term-and-condion">    <h3 class="mt-5" style={{marginBottom:'15px'}}>Privacy Policy</h3>    <p>        At BSTL Offshore Consultants, we are committed to protecting your privacy. This Privacy Policy outlines how         your personal information is collected, used, and shared when you use our website.     </p>     <h2>1. Information We Collect</h2>    <p>        We may collect personal information such as your name, email address, and phone number when you interact with         our website. This information is used to provide you with better services and support.     </p>     <h2>2. Commitment to Integrity</h2>    <p>        BSTL Offshore Consultants is committed to upholding the highest standards of integrity and professionalism in         all our operations.     </p>     <h2>3. Security Measures</h2>    <p>        We implement security measures to protect your personal information from unauthorized access, disclosure,         alteration, or destruction. However, no method of transmission over the internet or electronic storage is         entirely secure, and we cannot guarantee absolute security.     </p>     <h2>4. Consent</h2>    <p>        By using our website, you consent to our Privacy Policy and agree to its terms.     </p>
            </div>


        </div>
    )
}

export default Privacy
