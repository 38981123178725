import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';


function Brands() {
  const [images, setImages] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    axios.get('https://admin.bstloffshore.com/api/getBrandLogos')
      .then(res => {setImages(res.data.data); setIsLoading(false)})

  }, [0])


 
  const options = {
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 6,

      }
    }
  }

  return (

    
    <Container className='brand-container mb-5'  >
      {!isLoading ?
      <>
        <div className='brands'>
          <h3 className='text-center mb-5 h3-brand'>Brands We Cater</h3>

        </div>
        <OwlCarousel className='owl-theme' loop margin={3} {...options}
          items={1}
          autoplay
          autoplayTimeout={1000}
                    autoplaySpeed={1000}
        >
          {
            images && images.map((item,index) => {
              return (

                <img key={index} src={item.large_image_path + item.brand_image} className='barnd-img' loading='lazy'  alt='brandsimg' width="154" height="62"  />

              )
            })

          }

        </OwlCarousel>
      </>
      :
      <>
      <Row>
        <Col lg={12} className='text-center spinner-css' >
            <Spinner/>
            </Col>
            </Row>
      </>

      }

    </Container>

  
  
  )
}

export default Brands
