import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import parse from 'html-react-parser';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'

function Apllication() {
  const [file, setFile] = useState();
  const [isLoadings, setIsLoadings] = useState(true);
  const [application, setApplication] = useState();
  const [errors, setErrors] = useState();
  const [successMessage, setSuccessMessage] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message:''
  });


  var url = document.URL
  var page_name = url.substr(url.lastIndexOf('/') + 1);

  useEffect(() => {
    axios.post('https://admin.bstloffshore.com/api/getJobDetailsByJobSlug', { 'slug': page_name })
      .then(res => { setApplication(res.data.data); setIsLoadings(false); })
  }, [page_name])



  // getting form data
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  // getting file 
  const saveFile = (e) => {
    setFile(e.target.files[0]);
  };


  // submitting form


  const handleSubmit = (event) => {
    event.preventDefault();
    const convertedForm = new FormData();
    convertedForm.append("resume", file);
    for (var key in formData) {
      convertedForm.append(key, formData[key]);
    }
    convertedForm.append('job_id', application.id);

    axios.post('https://admin.bstloffshore.com/api/applyJob', convertedForm).then((res) => {
      setSuccessMessage(res)
      setFormData({
        name: '',
        email: '',
        phone: '',
        message:''
      });
      setFile(null); // Reset the file state
      event.target.reset(); // Reset the form
      event.target.value = null; // Empty the file field

    }).catch((error) => {
      setErrors(error)
    })
  };


  return (

    <>
      {isLoadings ? (
        <Container>
          <Row>
            <Col lg={12} className='text-center spinner-css '>
              <Spinner />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className='mb-5 application-container' >
          {application &&
            <Row>
              <Col lg={7} xs={12} className='col-lg-7-carrer'>
                <h3 className='mt-4 mb-4'>{application.position.position}</h3>
                <div>
                  {parse(application.job_description)}
                </div>
              </Col>
              <Col className='mt-4 mb-4 form-jd'>
                <div className='form-color'>
                  <h6 className='mb-3' >Apply Now</h6>
                  <p className='mb-2'><b>Id</b>&nbsp;&nbsp;&nbsp;    : {application.id}</p>

                  <p className='mb-2'> <b>Department </b>: {application.department.department_name}</p>
                  <p className='mb-2'><b>Position</b> &nbsp;&nbsp;&nbsp;&nbsp;   : {application.position.position}</p>
                  <p className='mb-3 capitalize' > <b>Location </b>&nbsp;&nbsp;&nbsp;&nbsp;: {application.location}</p>

                  <Form className='mt-4' onSubmit={handleSubmit}>
                    <Form.Group className='mt-2 mb-2' controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        name='name'
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      {errors && <Form.Label className="form-label-color">{errors.response.data.data.name}</Form.Label>}
                    </Form.Group>

                    <Form.Group className='mt-2' controlId="exampleForm.ControlInput2">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name='email'
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {errors && <Form.Label className="form-label-color">{errors.response.data.data.email}</Form.Label>}
                    </Form.Group>


                    <Form.Group className='mt-2' controlId="exampleForm.ControlInput3">
                      <Form.Control
                        type="text"
                        placeholder="Phone"
                        name='phone'
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                      {errors && <Form.Label className="form-label-color">{errors.response.data.data.phone}</Form.Label>}
                    </Form.Group>

                    <Form.Group className='mt-2' controlId="exampleForm.ControlInput3">
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        name='message'
                        value={formData.message}
                        onChange={handleInputChange}
                      />
                      {errors && <Form.Label className="form-label-color">{errors.response.data.data.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className='mt-2' controlId="exampleForm.ControlInput3">
                      <Form.Control
                        type="file"
                        name='resume'
                        onChange={saveFile}
                      />
                    </Form.Group>
                    {successMessage ? successMessage && <p>{successMessage.data.data}</p> : errors && <Form.Label className=" form-label-color" >{errors.response.data.data.resume}</Form.Label>}

                    <Button type="submit" className='mt-3 submit-btn'>
                      Submit
                    </Button>
                    <br></br>

                  </Form>

                </div>
              </Col>
            </Row>
          }
        </Container>
      )}
    </>

  )
}

export default Apllication
