import React, { useState, useEffect } from 'react';
import { Container ,Row,Col} from 'react-bootstrap';
import { BsGlobeCentralSouthAsia,BsFillBuildingsFill } from 'react-icons/bs';
import { GiAchievement, GiTrophyCup } from 'react-icons/gi';
import { MdPersonAddAlt1 } from 'react-icons/md';
import CountUp from 'react-countup';
import { GoPlus } from "react-icons/go";


const CounterComponent = () => {
  const [counters, setCounters] = useState([
    { icon: <BsGlobeCentralSouthAsia className='text-center' size={'42px'} color='#8CAEEC' />, value: 0, target: 6 , text: 'Countries'  },
    { icon: <MdPersonAddAlt1 className='text-center' size={'45px'} color='#8CAEEC'/>, value: 0, target: 2000, text: 'Employees',pulus:<GoPlus /> },
    { icon:  <GiTrophyCup className='text-center' size={'45px'} color='#8CAEEC'/>, value: 0, target: 11, text: 'Achievements',pulus:<GoPlus /> },
    { icon: <GiAchievement className='text-center' size={'45px'} color='#8CAEEC' />
    , value: 0, target: 45, text: 'Brands',pulus:<GoPlus /> },
    { icon: <BsFillBuildingsFill className='text-center' size={'42px'} color='#8CAEEC' />
    , value: 0, target: 40, text: 'Companies',pulus:<GoPlus /> },

  ]);
  const [countReached25, setCountReached25] = useState(false);


  useEffect(() => {
    const interval = setInterval(() => {
      setCounters((prevCounters) =>
        prevCounters.map((counter) => {
          const increment = (counter.target - counter.value) / 100;
          return {
            ...counter,
            value: Math.min(counter.value + increment, counter.target),
          };
        })
      );
    }, 15);

    return () => clearInterval(interval);
  }, []);



  useEffect(() => {
    const timer = setTimeout(() => {
      setCountReached25(true);
    }, 5750); // Adjust this value according to your CountUp duration

    return () => clearTimeout(timer);
  }, []);
  const handleCountUpEnd = () => {
    setCountReached25(true);
  };

  return (
    <>
   
    <Container  style={{ padding: '50px 20px' }}>
      <Row className="text-center mt-5" style={{display:'flex',justifyContent:'center'}}>
        {/* {counters.map((counter, index) => (
          <Col key={index} lg={2} xs={12} className='counter-col'>
            <div className="counter">
            <span className='mb-5'>{counter.icon}</span>
              <h2 className="timer count-title count-number counter-count mt-3">{Math.round(counter.value)}</h2>
              <p className="count-text">{counter.text}</p>
            </div>
          </Col>
        ))}
 */}

{counters.map((counter, index) => (
          <Col key={index} lg={2} xs={12} className='counter-col'>
            <div className="counter">
            <span className='mb-5'>{counter.icon}</span>
              {/* <h2 className="timer count-title count-number counter-count mt-3">{Math.round(counter.value)}</h2> */}

              {/* {!countReached25 ?   <h2 className="timer count-title count-number counter-count mt-3">
 
        <CountUp
          end={counter.target}
          duration={5.75}
          
        />
      </h2>  : (
       <h2 className=' timer count-title count-number counter-count mt-3 '>{counter.target}+</h2>
      )} */}
   
   <h2 className="timer count-title count-number counter-count mt-3">
 
 <CountUp
   end={counter.target}
   duration={5.75}
   
 /> {counter.pulus}
</h2>
 
        <p className="count-text">{counter.text}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>

    
    </>
  );
};

export default CounterComponent;
