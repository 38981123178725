import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from 'jquery';
import { Helmet } from 'react-helmet';


const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [galleryimg, setGalleryimg] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [photoIndex, setPhotoIndex] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const[metatage,setMetaTage]=useState()

  const loaction = useLocation()
 
 if (loaction.pathname === '/gallary' && isOpen === true) {
    $(".sticky-top").css("z-index", "auto");
  }
  else{
    $(".sticky-top").css("z-index", "1020");

  }

  useEffect(() => {
    axios
      .get('https://admin.bstloffshore.com/api/getGallerySections')
      .then(res => {
        setImages(res.data.data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });

    axios
      .post('https://admin.bstloffshore.com/api/getGalleriesBySection', { slug: 'show-all' })
      .then(res => {
        setGalleryimg(res.data.data);
      })
      .catch(error => {
        // Handle error
      });

      axios.get('https://admin.bstloffshore.com/api/getSeoMetaTagsBySlug/gallary')
      .then(response =>{ setMetaTage(response.data.data)})

  }, [0]);

  const getImagesByCategory = category => {
    setIsLoading(true);
    axios
      .post('https://admin.bstloffshore.com/api/getGalleriesBySection', { slug: category })
      .then(response => {
        setGalleryimg(response.data.data);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });

  };

  const prettyData = item => {
    const key = Object.keys(item)[0];
    const value = item[key].map((image, index) => ({
      ...image,
      index
    }));
    return value;
  };

 

  const getLightboxImages = () => {
    const lightboxImages = [];

    galleryimg.forEach(item => {
      const key = Object.keys(item)[0];
      const images = item[key].map((image, index) => ({
        src: image.large_image_path + image.image,
        title: image.id
      }));
      lightboxImages.push(...images);
    });

    return lightboxImages;
  };

  const lightboxImages = getLightboxImages();

 
  
  
  

  return (
    <>

{ metatage &&
<Helmet>
        <title>{metatage.seo.title}</title>
        <meta name="description" content={metatage.seo.description} />
        <meta name="keywords" content={metatage.seo.keywords} />
        <link rel="canonical" href={metatage.seo.canonical} />
        <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
      </Helmet>
}
      {isLoading ? (
        <Container>
          <Row>
            <Col lg={12} className="text-center spinner-css" >
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Container fluid>
            <h3 className="text-center mb-5 mt-5 h3-vision">Gallery</h3>
            <Row className='felids-row' >
              <Col lg={10}>
                <Row>
                  {images &&
                    images.map((item, index) => (
                      <Col lg={3} key={index}>
                        <h6 className="text-center img-link-names">
                          <Link onClick={() => getImagesByCategory(item.slug)}>{item.section_name}</Link>
                        </h6>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
            <Row  className="mt-5 main-row">
              <Col lg={10} >
                {galleryimg &&
                
                  galleryimg.map((item, index) => (
                    <div key={index}>
                      <Row className="mb-5 glary-row-h3" >
                        <h3  className="text-center mb-5">{Object.keys(item)[0]}</h3>
                        {prettyData(item).map((imageItem, imageIndex) =>
                        {
   
                      return  (
                       
                     // Store imageItem.id in a variable

                          <Col lg={3} md={4} key={imageIndex} className='main-col' >
                       
                            <img
                              src={ imageItem.large_image_path + imageItem.image}
                              alt={`Image ${imageItem.index}`}
                              width={'100%'}
                              height={'200px'}
                              className='galary-images'

                            
                              onClick={() => {
                                
                                {lightboxImages.map((item,index)=>{
                                item.title === imageItem.id ?
                             setPhotoIndex(index)
                                : setIsOpen(true);
                                  
                                })}
                             
                                setIsOpen(true)

                              
                              }}

                            />
                            <p className='text-center'>{imageItem.image_description}</p>
                          </Col>
                        )})}
                      </Row>
                    </div>
                  ))}
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col lg={8}>
          {isOpen && (

            <Lightbox
             mainSrc={lightboxImages[photoIndex].src } 
              nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length].src}
              prevSrc={lightboxImages[(photoIndex + lightboxImages.length - 1) % lightboxImages.length].src}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + lightboxImages.length - 1) % lightboxImages.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightboxImages.length)}
            />
          )}
          </Col>
</Row>
          
</Container>
         
        </>
      )}
    </>
  );
};

export default ImageGallery;


