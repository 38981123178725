import React, { useEffect, useState } from 'react'
import { Container,Row,Col } from 'react-bootstrap';
import {FaFacebookF,FaLinkedinIn,FaInstagramSquare, FaTwitter} from 'react-icons/fa';
import {AiOutlineLinkedin} from 'react-icons/ai'
import { Link } from 'react-router-dom';
import axios from 'axios';


function Footer() {

    const[footerdata,setFooterData]=useState()

    useEffect(()=>{
        axios.get('https://admin.bstloffshore.com/api/getFooterData')
        .then(res=>{setFooterData(res.data.data)})

    },[footerdata])
  return (
    <>
    <Container fluid>
        <Row className="footer-row" >
           
            <Col lg={12}>
                <Row className='footer-row-2 mt-5 mb-5'>
                   
                    <Col lg={2} md={3}  >
                        <h5>Learn More</h5>
                        <p style={{fontSize:'13px'}}><Link to='/about-us'> About Us</Link></p>
                        <p   style={{fontSize:'13px'}}><Link to='/our-business'>Our Business </Link> </p>
                       
                        <p style={{fontSize:'13px'}}><Link to='/career'> Career</Link></p>
                        <p style={{fontSize:'13px'}}><Link to='/Achievements'>  Achievements</Link></p>
                      
                    </Col>
                    

                     
                    <Col lg={4}  md={6} className='get-in-toch'>
                        {/* <h5>Get In Touch</h5>
                        <p style={{fontSize:'13px'}}>111 & 112 Workafella Business Center <br></br>Cyber Crown, Village HUDA Tech Enclave<br></br> Madhapur, Hyderabad, Telangana 500081
                        <br></br>Tel : +91 040 6965 1100</p> */}
                        {footerdata && footerdata.footer_section.company_address_en
                        }
                    </Col>
                   

                    <Col lg={2}  md={3} className='visti'>
                        <h5 style={{marginLeft:'6%'}}>Visit us  at </h5>
                        <div>
                    <span className='fb-icon'> <a href='https://www.facebook.com/profile.php?id=100093268437614'  target='_blank'><FaFacebookF color='white'/></a></span>  

                    <span style={{padding:'0px',marginRight:'1%'}}><a href='https://www.linkedin.com/company/bstl-offshore-consultants/' target='_blank' > <AiOutlineLinkedin color='white' size={'30px'}/></a></span>  
        {/* <span>  <FaInstagramSquare color='white'/></span>  */}
        {/* <span><FaTwitter color='white'/></span>  */}
        </div>
                         </Col>
                         <Col lg={2}>
                         <h5>Important Links</h5>
                        <p style={{fontSize:'13px'}}><Link to='/term-and-conditions'>Terms & Condtions</Link></p>
                        <p   style={{fontSize:'13px'}}><Link to='/privacy-policy'>Privacy  Policy
 </Link> </p>
                       
                        
                         </Col>
                        
                </Row>
            </Col>

           

        </Row>

    </Container>
      
    </>
  )
}

export default Footer
