import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet';

function Companies() {

  const [countries, setCountries] = useState()
  const [companies, setCompanies] = useState();
  const [allcompanies, setAllCompanies] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [metatage, setMetaTage] = useState()

  var url = document.URL
  var page_name = url.substr(url.lastIndexOf('/') + 1);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true)
    axios.get('https://admin.bstloffshore.com/api/getOurCompanies')
      .then(response => {
        // Handle the successful response
        setCountries(response.data.data.countries); // Access the response data
        const uniqueData = removeDuplicates(response.data.data.companies, 'company_name');
        setCompanies(uniqueData); // Access the response data
        setAllCompanies(uniqueData);
        setIsLoading(false)
      })
      .catch(error => {
        // Handle any error that occurred
        console.error(error);
        setIsLoading(false)
      }, [location]);

    axios.get('https://admin.bstloffshore.com/api/getSeoMetaTagsBySlug/our-business')
      .then(response => { setMetaTage(response.data.data) })

  }, [0])



  function removeDuplicates(array, property) {
    if (!property) {
      // Remove duplicates based on the entire object
      return Array.from(new Set(array));
    }

    const seen = {};
    return array.filter((item) => {
      const key = item[property];
      if (!seen[key]) {
        seen[key] = true;
        return true;
      }
      return false;
    });
  }


  const getCompaniesByCountry = ((country) => {
    setIsLoading(true)
    axios.post('https://admin.bstloffshore.com/api/getCompaniesBelongistCountry', { 'country_slug': country })
      .then(response => {
        setCompanies(response.data.data);
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false)
      }, []);

  })

  const getAllCompanies = (() => {
    setCompanies(allcompanies)
  })


  return (
    <>

      {metatage &&
        <Helmet>
          <title>{metatage.seo.title}</title>
          <meta name="description" content={metatage.seo.description} />
          <meta name="keywords" content={metatage.seo.keywords} />
          <link rel="canonical" href={metatage.seo.canonical} />
          <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
        </Helmet>
      }
      {isLoading ?
        <Container>
          <Row>
            <Col lg={12} className='text-center spinner-css' >
              <Spinner />
            </Col>
          </Row>
        </Container>
        :
        <Container fluid className='contry-p-tag'>
          <Row className='mt-5 mb-5 flags-row' >
            <Col lg={4} xs={12} >
              <h3 className=''> Our Business</h3>

            </Col>
            <Col lg={7} className='text-center'
              
            >
              <Row >
                <Col lg={2} xs={4} className='mt-2 flages'>
                  <Button className='view-all' onClick={() => getAllCompanies()} >
                    View All
                  </Button>
                </Col>


                {
                  countries && countries.map((item,index) => {
                    return (

                      <Col lg={2} xs={4} key={index} className='flages-new-css'>
                        <Link><img src={item.counrty_icon_path + item.country_icon} onClick={() => getCompaniesByCountry(item.slug)} width={'50px'}  alt='flag-image' /></Link>
                        <p>{item.country_name}</p>
                      </Col>
                    )
                  })
                }
              </Row>
            </Col>

          </Row>
          <Row className='hr-line'>
            <hr style={{ display: 'flex', justifyContent: 'center', width: '85%' }}></hr>
          </Row>
          <Row className='mb-5 mt-4' >
            {companies && companies.map((company, companyIndex) => {
              return (
                <Col lg={5} md={5} xs={12} key={companyIndex} >
                  <Link to={"/our-business/" + company.slug}><p  className='link-tag-company'>{company.company_name}</p></Link>
                </Col> )})}
          </Row>
        </Container>
      }

    </>
  )
}

export default Companies

