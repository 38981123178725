import React, { useEffect, useState } from 'react';
import { Card, Carousel, Col, Container, Row, Spinner } from 'react-bootstrap';
import { BsBullseye } from "react-icons/bs"
import { FaEye, FaHands } from 'react-icons/fa';
import $ from 'jquery';
import 'animate.css';
import { Link } from 'react-router-dom';
import Brands from './Brands';
import axios from 'axios';
import parse from 'html-react-parser';
import OwlCarousel from 'react-owl-carousel';
import CounterComponent from '../Companies/Couterapp';
import { Helmet } from 'react-helmet';
import HTMLReactParser from 'html-react-parser';
function Homepage() {
  const [banner, setBanner] = useState()
  const [about, setAbout] = useState()
  const [vision, setVision] = useState()
  const [loading, setLoading] = useState(true)
  const [metatage, setMetaTage] = useState()


  useEffect(() => {
    axios.get('https://admin.bstloffshore.com/api/getBanners')
      .then(response => { setBanner(response.data.data); setLoading(false) })
    axios.get('https://admin.bstloffshore.com/api/getAboutUs')
      .then(resp => setAbout(resp.data.data.aboutus_details))
    axios.get('https://admin.bstloffshore.com/api/getOurvision')
      .then(responses => setVision(responses.data.data))
    $('.counter-count').each(function () {
      $(this).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 25000,
        easing: 'swing',
        step: function (now) {
          $(this).text(Math.ceil(now));
        }
      });
    });
    axios.get('https://admin.bstloffshore.com/api/getSeoMetaTagsBySlug/home')
      .then(response => { setMetaTage(response.data.data) })
  }, [0])

  console.log(about)
  return (
    <>

      {metatage &&
        <Helmet>
          <title>{metatage.seo.title}</title>
          <meta name="description" content={metatage.seo.description} />
          <meta name="keywords" content={metatage.seo.keywords} />
          <link rel="canonical" href={metatage.seo.canonical} />
          <meta name="robots" content={metatage.seo.robots} />
          <meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
          <meta property="og:description" content={metatage.seo.og_description} />
          <meta property="og:image" content={metatage.seo.og_image} />
          <meta property="og:url" content={metatage.seo.og_url} />
          <meta property="og:type" content={metatage.seo.og_type} />
          <meta property="og:locale" content={metatage.seo.og_locale} />
        </Helmet>
      }
      {loading ? (
        <Container>
          <Row>
            <Col lg={12} className='text-center  spinner-css' >
              <Spinner />
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Carousel indicators={false}  controls={true} speed={1000}>
            {banner && banner.map((item, index) => {
              return (

                <Carousel.Item key={index}>


                  <img
                    src={item.large_image_path + item.slider_image}
                    alt="First slide" width={'100%'} height={'auto'} className='main-banner-images' />

                </Carousel.Item>

              )
            })}

          </Carousel>


          <Container fluid >
            <Row className='home-about-row'>
              <Col lg={11} className='home-about '>
                <Row >
                  <Col lg={6} className='carousel-them'>
                    {about &&
                      <OwlCarousel items={1}
                        className="owl-theme"
                        loop
                        margin={8} >


                        <div className='text-center div-1'    >
                          <div className='div-2'>
                            <img src={about && about.large_image_path + about.left_side_image} width="450" height="auto" alt='banners' />

                          </div>

                        </div>
                        {/* <div className='text-center div-1'    >
                          <div className='div-2'>
                            <img src={about && about.large_image_path + about.left_side_image} width="580" height="280" alt='banners' />

                          </div>

                        </div> */}



                      </OwlCarousel>}
                  </Col>
                  <Col lg={6} className='col-about-content'>
                    <div>
                      <h1 style={{textTransform:'capitalize'}}>About Us </h1>
                      <p className='about-comtent'>
                        { about && about.short_description && HTMLReactParser(about.short_description )}
                      </p>
                      <button className="rounded-pill">
                        <Link to="/about-us">Read More..</Link>
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            {vision &&
              <Row className='our-vision-row '>
                <h3 className=' h3-vision'>Our Vision</h3>

                <Col lg={3} md={4} className="animate__animated  animate__delay-2s animate__zoomIn">
                  <Card className='vision-our' >
                    <span><FaEye /></span>
                    <h5 className='vision-title'>{vision.vision_title}</h5>
                    {parse(vision.vision_description)}
                  </Card>
                </Col>
                <Col lg={3} md={4} className="animate__animated animate__bounce animate__delay-3s animate__zoomIn" >
                  <Card className='mission-our' >
                    <span ><BsBullseye /></span>
                    <h5  >{vision.mission_title}</h5>
                    {parse(vision.mission_description)}
                  </Card>
                </Col>
                <Col lg={3} md={4} className="animate__animated animate__bounce animate__delay-4s animate__zoomIn" >
                  <Card className='value-our' >
                    <span><FaHands /></span>
                    <h5  >{vision.value_title}</h5>
                    <ul className='text-center our-values' >
                      <li className="home-value-li"> Integrity </li>
                      <li className="home-value-li"> Ownership </li>
                      <li className="home-value-li"> Passion   </li>
                      <li className="home-value-li"> Excellence</li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            }

            <Row className='main_counter' >

              <CounterComponent />
            </Row>

            <Row className='last-row' >
              <Col >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="rgb(237,242,252)" fillOpacity="1" d="M0,160L1440,32L1440,0L0,0Z"></path></svg>
              </Col>
            </Row>
          </Container>

          <Brands />

        </>
      )}
    </>
  )
}

export default Homepage
