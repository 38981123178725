import React from 'react'
import { Container,Row,Col, Button} from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'


function Companies() {


 


  return (
    <>
    <Container fluid  className='contry-p-tag' >
      <Row className='mt-5 mb-5' style={{display:'flex',justifyContent:'center'}}>
        <Col lg={4}  style={{padding:'0px 0px 0px 40px'}}>
          <h3 className='text-start'> Our Business</h3>

        </Col>
        <Col lg={7} className='text-center'
        style={{padding:'0px 25px 0px 25px'}}
        
        >
          <Row >
            <Col lg={2} style={{
              display:'flex',
              justifyContent:'center',
              marginTop:'10px'
    
            }}>
           <Link to={'/companies'}>   <Button className='view-all'>
                View All
              </Button></Link>
             </Col>
            <Col lg={2} style={{
           display:'grid',
              alignItems:'center',
              justifyContent:'center',
            

    
            }}>


      <Link to="/india">   <img src='/Asets/logo/india.png' width={'50px'}/></Link> 
      <p>India</p>
    
         
          </Col>
          <Col lg={2} style={{
           display:'grid',
              alignItems:'center',
              justifyContent:'center'


    
            }}>
     <Link to={'/oman'}>  <img src='/Asets/logo/oman.png' width={'50px'} 

      
          
          
          /></Link>
          <p>Oman</p>
          </Col>
          <Col lg={2} style={{
           display:'grid',
              alignItems:'center',
              justifyContent:'center'

    
            }}>
       
     <Link to={'/uae'}>    <img src='/Asets/logo/united-arab-emirates.png' width={'50px'} /></Link>  
     <p>Uae</p>


          </Col>
          <Col lg={2} style={{
           display:'grid',
              alignItems:'center',
              justifyContent:'center'

    
            }}>


    <Link to={'/ksa'}>     <img src='/Asets/logo/saudi-arabia.png' width={'50px'} className='mt-1'/></Link> 
    <p>Ksa</p>


          </Col>
          <Col lg={2} style={{
           display:'grid',
              alignItems:'center',
              justifyContent:'center'
    
            }}>


       <p>  <Link to={'/qatar'}>  <img src='/Asets/logo/qatar.png' width={'50px'} className='mt-1'/></Link>  </p>
       <p>Qatar</p>


          </Col>
          </Row>
        </Col>

      </Row>

     
        
       <Row style={{display:'flex',justifyContent:'center'}} className='mb-5 mt-5'>
       <hr style={{width:'85%'}}></hr>
        <Col lg={5} style={{display:'grid',justifyContent:'center'}} className='mt-5 '>
         
        {/* <p>Al Qantar International SPC</p> */}
      

        
          <Link to={'/c1'}>     <p>Alpha Motors LLC</p></Link>

      {/* <p>Al Qantar International SPC</p> */}
      <Link to={'/c3'}>      <p>Arabian Gulf Automobiles and Equipment LLC</p></Link>  
      <Link to={'/c9'}>      <p>Bahwan International Group LLC</p></Link>  
      <Link to={'/c10'}>    <p>Bahwan Motors Auto Accessories Trading LLC</p></Link>  
      <Link to={'/c11'}>     <p>Bahwan Services & Trading LLC</p></Link>  
      <Link to={'/c14'}>    <p>Elegant Enterprises & Car Servicess LLC</p></Link>  
        <Link to={'/c15'}>  <p>Global Automotive and Equipment Trade LLC</p></Link> 

        <Link to={'/c16'}>    <p>GreenTech Energy and Water LLC </p></Link>  




        </Col>

        <Col lg={5}  style={{display:'grid',justifyContent:'center'}} className='mt-5'>
          
        
        

        <Link to={'/c18'}>   <p>Gulf Great Sands LLC</p></Link>  
        <Link to={'/c19'}>   <p>Gulf Security Services LLC</p></Link>  
        <Link to={'/c20'}>    <p>Health Spring LLC</p></Link>  
        <Link to={'/c21'}>    <p>Homeland Plants LLC </p></Link>  
          {/* <p>International Automobiles Services SPC</p> */}
          <Link to={'/c22'}>    <p>International Integrated Equipment LLC</p></Link>  
          <Link to={'/c24'}>   <p>Saba Bahwan Advocates & Legal Consultants</p></Link>  
          <Link to={'/c25'}>   <p>Sadad International LLC</p></Link>  
          <Link to={'/c26'}>    <p>Service My Car LLC</p></Link>  
          
        </Col>
       </Row>
    </Container>
   
    </>
  )
}

export default Companies

