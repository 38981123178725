import React from 'react'

function Term() {
    return (
        <div>
            <div class="container mb-5 term-and-condion">
                <h3 class="mt-5">Terms and Conditions</h3>
                <p>        Welcome to BSTL Offshore Consultants Pvt Ltd! By accessing or using our website, you agree to comply with and         be bound by the following terms and conditions.     </p>     <h2>1. Acceptance of Terms</h2>    <p>        By using our website, you agree to be bound by these Terms and Conditions, all applicable laws, and regulations,         and agree that you are responsible for compliance with any applicable local laws.     </p>     <h2>2. Services</h2>    <p>        BSTL Offshore Consultants specializes in providing comprehensive support services, including back office         operations in accounting, finance, human resources, IT, procurement, and administration.     </p>     <h2>3. User Conduct</h2>    <p>        When using our website, you agree not to engage in any conduct that may interfere with or disrupt the proper         functioning of our services. This includes, but is not limited to, uploading or transmitting any harmful code         or unauthorized content.     </p>     <h2>4. Intellectual Property</h2>    <p>        The content on this website, including but not limited to text, graphics, logos, and images, is the property         of BSTL Offshore Consultants and is protected by applicable intellectual property laws.     </p>
            </div>
        </div>
    )
}

export default Term
