import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import axios from "axios";
import { useState } from 'react';
import { Row, Col, Container, Card, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import { Helmet } from 'react-helmet';


export default function Dynamic_page() {
 
  const [isLoading, setIsLoading]=useState(false);
  const [page_data, setPage_data]=useState();
  const[metatage,setMetaTage]=useState()


  var url = document.URL
  var page_name=url.substr(url.lastIndexOf('/') + 1);
 
  const location = useLocation();

  useEffect(()=>{
    setIsLoading(true)
    axios.get('https://admin.bstlglobal.com/api/v1/dynamic_pages/'+page_name)
    .then(function (response) {
      setPage_data(response.data)
      setIsLoading(false)
    })

    axios.get('https://admin.bstloffshore.com/api/getSeoMetaTagsBySlug/our-business')
  .then(response =>{ setMetaTage(response.data.data)})
    
  },[location]);


  
            


if(isLoading){

  return (
    <>
    
    </>
  )

}else{

  return (
    <>


{ metatage &&
<Helmet>
  
        <title>{metatage.seo.title}</title>
        <meta name="description" content={metatage.seo.description} />
        <meta name="keywords" content={metatage.seo.keywords} />
        <link rel="canonical" href={metatage.seo.canonical} />
        <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
      </Helmet>
}

    {page_data&&
      <>
        <Carousel className='inside-crousel' indicators={false} controls={true} 
        autoPlay={true}
        interval={1000}
    
        >
            {
              JSON.parse(page_data.images).map((element, index) => {
                return (
                  <Carousel.Item  key={index}>
                    <img src={"https://admin.bstlglobal.com/"+element}
                      className="d-block"
                      width={'100%'}
                      height={'100%'}


                    />
                  </Carousel.Item>
                )
              })
            }
        </Carousel>

        <Container fluid className='inside-container'>
          <Row>

            <Col lg={8} md={7} xs={12} sm={12} style={{ paddingRight:'60px', display: 'flex', justifyContent: 'center' }} className="inside-col">
              <div className='inside-page-content '>
                <h4>{page_data.title} </h4>
                {Parser(page_data.content)}
              </div>
            </Col>
            <Col lg={4} md={5} sm={12}  className=" inside-page-col-link">
             <div className='inside-page-link-main' >
              {page_name=='energy'&&
                <div className='inside-page-links'>
        <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/green">Green Tech Energy & Watter LLC</Link></p>
        <p><Link  to="/page/bahwan">Bahwan RenewableEnergy Company  LLC</Link></p>
        <p><Link to="/page/hyunet">Hyunet Private Ltd</Link></p>
       {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}
                </div>
                }

                {page_name=='ABB_LLC' &&
                <div  className='inside-page-links'>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/green">Green Tech Energy & Watter LLC  </Link></p>
        <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p>

        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>
    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}
                </div>
                }

{page_name=='hitachi' &&
                <div  className='inside-page-links'>
              <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/green">Green Tech Energy & Watter LLC  </Link></p>
        <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p>

        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>
    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}
                </div>
                }

              

              {page_name=='green'&&
                <div  className='inside-page-links'>
             <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/green">Green Tech Energy & Watter LLC  </Link></p>
        <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p>

        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>
    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}                </div>
                }

              {page_name=='bahwan'&&
                <div  className='inside-page-links'>
             <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/green">Green Tech Energy & Watter LLC  </Link></p>
        <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p>

        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>
    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}                </div>
                }
                   {page_name=='hyunet'&&
                <div  className='inside-page-links'>
             <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/green">Green Tech Energy & Watter LLC  </Link></p>
        <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p>

        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>
    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}                </div>
                }
                   {page_name=='thunder'&&
                < div  className='inside-page-links'>
             <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/green">Green Tech Energy & Watter LLC  </Link></p>
        <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p>

        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>
    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}                </div>
                }
                {
                  page_name=='food'&&
                  <div  className='inside-page-links'>

                      <p><Link to="/page/homeland">Homeland</Link></p>
                  </div>
                }
                {
                  page_name=="health"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    <p><Link to="/page/care">Care Plus LLC</Link></p>

                    </div>
                  
                }
                 {
                  page_name=="apollo"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    <p><Link to="/page/care">Care Plus LLC</Link></p>

                    </div>
                  
                }
                 {
                  page_name=="international"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    <p><Link to="/page/care">Care Plus LLC</Link></p>

                    </div>
                  
                }
                 {
                  page_name=="super"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    <p><Link to="/page/care">Care Plus LLC</Link></p>

                    </div>
                  
                }
                 {
                  page_name=="care"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    <p><Link to="/page/care">Care Plus LLC</Link></p>

                    </div>
                  
                }
                {
                  page_name=='asset'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>
                }
                
                {
                  page_name=='general'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>
                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>
                }
                 {
                  page_name=='gulf'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>
                }
                 {
                  page_name=='lamnalco'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>
                }
                 {
                  page_name=='rosenbauer'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>
                }
                 {
                  page_name=='cantiere'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>
                }
                 {
                  page_name=='vanderlande'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>
                }
                 {
                  page_name=='loconav'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>
                }
                {
                  page_name=="packaging"&&
                  <div  className='inside-page-links'>
                     <p><Link to="/page/woodenm">Wooden Pallet Manufacturing</Link></p>
                     <p><Link to="/page/woodenr">Wooden Pallet Rentals</Link></p>
                    

                    </div>
                }
                 {
                  page_name=="woodenm"&&
                  <div  className='inside-page-links'> 
                     <p><Link to="/page/woodenm">Wooden Pallet Manufacturing</Link></p>
                     <p><Link to="/page/woodenr">Wooden Pallet Rentals</Link></p>
                    

                    </div>
                }
                 {
                  page_name=="woodenr"&&
                  <div  className='inside-page-links'>
                     <p><Link to="/page/woodenm">Wooden Pallet Manufacturing</Link></p>
                     <p><Link to="/page/woodenr">Wooden Pallet Rentals</Link></p>
                    

                    </div>
                }
                {
                  page_name=="realestate"&&
                  <div  className='inside-page-links'>
                      <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p>
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                {
                  page_name=="ejada"&&
                  <div  className='inside-page-links'>
                      <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p>
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                {
                  page_name=="techno"&&
                  <div  className='inside-page-links'>
                      <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p>
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                {
                  page_name=="intelligent"&&
                  <div  className='inside-page-links'>
                      <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p>
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                {
                  page_name=="ipark"&&
                  <div  className='inside-page-links'>
                      <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p>
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                
                {
                  page_name=="north"&&
                  <div  className='inside-page-links'>
                      <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p>
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                
                
                {
                  page_name=="fashion"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/retail">Ash Amaira Retail Private Limited</Link></p>

                    <p><Link to="/page/accessories" >Ash Amaira Accessories Trading LLC</Link></p>
                    </div>
                }
                  {
                  page_name=="retail"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/retail">Ash Amaira Retail Private Limited</Link></p>

                    <p><Link to="/page/accessories" >Ash Amaira Accessories Trading LLC</Link></p>
                    </div>
                }
                  {
                  page_name=="accessories"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/retail">Ash Amaira Retail Private Limited</Link></p>

                    <p><Link to="/page/accessories" >Ash Amaira Accessories Trading LLC</Link></p>
                    </div>
                }
                {
                  page_name=="financial"&&
                  <div  className='inside-page-links'>
                    <p><Link to="/page/sadad">Sadad International LLC</Link></p>
                    </div>
                }
                 {
                  page_name=="bstlprofile"&&
                  <div  className='inside-page-links'>
                    <p><Link to="/management">Management</Link></p>
                    <p><Link to="/vision">Vision , Mission , Value </Link></p>
                    </div>
                }
                
                 

             

                </div> 
            </Col>

           
          </Row>
        </Container>
      </>
    }
    
    </>
  )
}
}
