import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container,Row,Col, Carousel, Spinner } from 'react-bootstrap'
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

function About() {
  const[data,setData]=useState()
  const[isLoading,setIsLoadings]=useState(true)
  const[metatage,setMetaTage]=useState()

  useEffect(()=>{
    axios.get('https://admin.bstloffshore.com/api/getAchievementDetails')
    .then(res =>{ setData(res.data.data);setIsLoadings(false)})

    axios.get('https://admin.bstloffshore.com/api/getSeoMetaTagsBySlug/achievements')
    .then(response =>{ setMetaTage(response.data.data)})
      
  },[0])

  return (
    <>

{ metatage &&
<Helmet>
        <title>{metatage.seo.title}</title>
        <meta name="description" content={metatage.seo.description} />
        <meta name="keywords" content={metatage.seo.keywords} />
        <link rel="canonical" href={metatage.seo.canonical} />
        <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
      </Helmet>
}

{
    isLoading ? (
      <Container>
<Row>
  <Col lg={12} className='text-center spinner-css ' >
      <Spinner/>
      </Col>
      </Row>
      </Container>
    ):(
   <div>

    

    {data && 
<Container fluid className='achivement-page'>
 
    <Carousel  indicators={false} controls={false} >
        { data.achievement_sliders.map((item,index)=>{
      return(
      <Carousel.Item key={index} >
      <img  src={item.large_image_path + item.achievement_slider_image} alt='banner-img'  width={'100%'}/>
      </Carousel.Item>
          )
        })}
    </Carousel>


    <Container  className='about-us mt-2 mb-5'>
   

        <Row className='achivement-page-content-row' >
            <Col className='text-center mt-5' lg={5} md={5}>
              <Carousel indicators={false} interval={700}>
                {/* <Carousel.Item>
              <img src='/Asets/Banners/Banner5.jpg' alt='carouselimg'  />
              </Carousel.Item> */}
             
              <Carousel.Item>
              <img src='/Asets/logo/ac1.jpg'  alt='carouselimg'  />
              </Carousel.Item>
              </Carousel>
            </Col>
            <Col lg={6} md={7}  >
            <h3 className='text-start mt-2 mb-4'>{data.achievement_details.title}</h3>

             {parse(data.achievement_details.description)}
    
   
     </Col>
     </Row>
     </Container>
     </Container>
    }

</div>
)}
    </>
  )
}

export default About
