import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container,Row,Col, Table, Button, Carousel, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

function Career() {

const[career,setCareer]=useState()
const[banner,setBanner]=useState()
const[loading,setLoading]=useState(true)
const[metatage,setMetaTage]=useState()

useEffect(()=>{
  axios.get('https://admin.bstloffshore.com/api/getJobsLists')
  .then(resp =>setCareer(resp.data.data))
  axios.get('https://admin.bstloffshore.com/api/getCareerDetails')
  .then(response => {
    // Handle the successful response
    setBanner(response.data.data)
    setLoading(false)
  })
  .catch(error => {
    // Handle any error that occurred
    console.error(error);
  });
  
  axios.get('https://admin.bstloffshore.com/api/getSeoMetaTagsBySlug/career')
  .then(response =>{ setMetaTage(response.data.data)})
    
},[0])


  return (
    <>
    { metatage &&
<Helmet>
        <title>{metatage.seo.title}</title>
        <meta name="description" content={metatage.seo.description} />
        <meta name="keywords" content={metatage.seo.keywords} />
        <link rel="canonical" href={metatage.seo.canonical} />
        <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
      </Helmet>
}


{loading ? (
      <Container>
<Row>
  <Col lg={12} className='text-center spinner-css' >
      <Spinner/>
      </Col>
      </Row>
      </Container>
    ):(

       <Container fluid  className='career mb-4'>
<Row className='carousel-row'>
       <Carousel >
        {banner && banner.career_sliders.map((item,index)=>{
          return(
            <Carousel.Item key={index}>
                  <img src={item.large_image_path + item.career_slider_image}  alt='banner-img'  width={'100%'}/>
                </Carousel.Item>
                )
              })}
                </Carousel>


                </Row>

        {  

        banner &&
          
        <Row className='content-row'>


           
            <Col lg={11} >
     <h3 className='text-center mt-5'>{banner.career_detaiils.first_title}</h3>
     <h4 className='text-center ' style={{fontWeight:'300'}}>{banner.career_detaiils.second_title}</h4>
     <h6 className='text-center' style={{fontWeight:'350'}}>{banner.career_detaiils.third_title}</h6>
    {parse(banner.career_detaiils.description)}
     </Col>
     {/* <Col lg={11} sm={12} xs={12}className='mt-5' >
     <Table striped bordered hoverb="true" className='table-responsive'>

      <thead  className='table-head' >
        <tr className='text-center'>
          <th>#</th>
          <td>Job Code</td> 
          <th>Department</th>
          
          <th>Position</th>
          <th>No. of Vacancy</th>
          <th>Location</th>
          <th>Action</th>
        </tr>
      </thead>  
    
      <tbody className='table-body' >

      {career && career.map((item,index)=>{
        return(
        <tr key={index}>
          <td className='text-center'>{index+1}</td>
          <td className='text-start'>{item.job_code}</td>

          <td className='text-start'>{item.department.department_name}</td>
          <td className='text-start'>{item.position.position}</td>
          <td className='text-center' >{item.no_of_vacancy}</td>
          <td className='text-center capitalize' >{item.location}</td>
          <td  className='text-center'><Link to={item.slug}><Button className='view-description-btn'  >View Description & Apply</Button></Link> </td>
        </tr>
       
       )
      })}
      </tbody>


    
     
    </Table>
     </Col> */}




    <Col lg={11} sm={12} xs={12} className='mt-5'>
  <div className='table-responsive'>
    <Table striped bordered hover className='table-responsive'>
      <thead className='table-head'>
        <tr className='text-center'>
          <th>#</th>
          <th>Job Code</th> 
          <th>Department</th>
          <th>Position</th>
          <th>No. of Vacancy</th>
          <th>Location</th>
          <th>Action</th>
        </tr>
      </thead>  
    
      <tbody className='table-body'>
        {career && career.map((item, index) => (
          <tr key={index}>
            <td className='text-center'>{index + 1}</td>
            <td className='text-start'>{item.job_code}</td>
            <td className='text-start'>{item.department.department_name}</td>
            <td className='text-start'>{item.position.position}</td>
            <td className='text-center'>{item.no_of_vacancy}</td>
            <td className='text-center capitalize'>{item.location}</td>
            <td className='text-center'>
              <Link to={item.slug}>
                <Button className='view-description-btn'>View Description & Apply</Button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
</Col>

     </Row>
}
     </Container>
      )}
    </>
  )
}

export default Career
