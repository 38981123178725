import React, { useEffect, useState } from 'react'
import { Container ,Row,Col} from 'react-bootstrap'
import {MdLocationOn , MdEmail} from 'react-icons/md'
import {FaPhoneAlt} from 'react-icons/fa'
import { Helmet } from 'react-helmet';
import axios from 'axios'
import HTMLReactParser from 'html-react-parser';

function Contact() {

  const[metatage,setMetaTage]=useState()
  const[Contactdata,setContactData]=useState()


  useEffect(()=>{
  
    axios.get('https://admin.bstloffshore.com/api/getSeoMetaTagsBySlug/contact')
    .then(response =>{ setMetaTage(response.data.data)})
    axios.get('https://admin.bstloffshore.com/api/getAddressOfContactUs')
    .then(res=>{setContactData(res.data.data)})
      
    
  },[0])

  
  return (
    <>


{ metatage &&
<Helmet>
        <title>{metatage.seo.title}</title>
        <meta name="description" content={metatage.seo.description} />
        <meta name="keywords" content={metatage.seo.keywords} />
        <link rel="canonical" href={metatage.seo.canonical} />
        <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
      </Helmet>
}
    <Container fluid className='contact-page-container' >

      <Row className='row-1' >
        <Col lg={10} className='mt-5 text-center' >
         <Row className='contact-icon-row'>
          

          <Col xs={12} lg={4} md={4} >
          <div>
            <FaPhoneAlt className='circle-icon mb-2'  />
            <h5>Phone</h5>
            <p>Tel : {Contactdata && Contactdata.phone}</p>
          </div>
          </Col>

          <Col  xs={12}  lg={4} md={4} >
          <div>
            <MdEmail  className='circle-icon mb-2'  />
            <h5>Email Addresses</h5>
            <p>{Contactdata && Contactdata.email
            }</p>
            
          </div>
          </Col>

          <Col  xs={12}  lg={4} md={4} >
          <div>
            <MdLocationOn  className='circle-icon mb-2'   />
            <h5>Address</h5>
            {/* <p>111 & 112 Workafella Business Center
Cyber Crown, Village HUDA Tech Enclave Madhapur, Hyderabad, Telangana 500081

</p> */}
{Contactdata && Contactdata.address && HTMLReactParser(Contactdata.address)}
 
          </div>
          </Col>
         </Row>

        </Col>
      </Row>




        <Row className='row-2'  >
            <Col lg={10} className='mb-5 mt-5'  >
               {/* <hr style={{width:'100%'}} className='mb-5'></hr> */}
            <iframe src={Contactdata && Contactdata.map_link} width="100%" height="300px" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

   
        
    </Col>
    
  
    </Row>
    </Container> 
    </>
  )
}

export default Contact
