import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Carousel, Spinner } from 'react-bootstrap'
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';


function C1() {
  const [isLoading, setIsLoading] = useState(true);
  const [application, setApplication] = useState()
  const[metatage,setMetaTage]=useState()

  var url = document.URL
  var page_name = url.substr(url.lastIndexOf('/') + 1);

  useEffect(() => {
    axios.post('https://admin.bstloffshore.com/api/getCompnayDetails', { 'company_slug': page_name })
      .then(res => { setApplication(res.data.data); setIsLoading(false); })

      axios.get('https://admin.bstloffshore.com/api/getSeoMetaTagsBySlug/our-business')
      .then(response =>{ setMetaTage(response.data.data)})
  }, [])

  return (
    <>

{ metatage &&
<Helmet>
        <title>{metatage.seo.title}</title>
        <meta name="description" content={metatage.seo.description} />
        <meta name="keywords" content={metatage.seo.keywords} />
        <link rel="canonical" href={metatage.seo.canonical} />
        <meta name="robots" content={metatage.seo.robots}/>
<meta property="og:title" content="How to Become an SEO Expert (8 Steps)" />
<meta property="og:description" content={metatage.seo.og_description} />
<meta property="og:image" content={metatage.seo.og_image} />
<meta property="og:url" content={metatage.seo.og_url} />
<meta property="og:type" content={metatage.seo.og_type} />
<meta property="og:locale" content={metatage.seo.og_locale} />
</Helmet>
}
{isLoading ? (
      <Container>
<Row>
      <Col lg={12} className='text-center spinner-css' >
      <Spinner/>
      </Col>
      </Row>
      </Container>
    ):(
        <Container fluid className='inside-page' >
          {
            application &&
            <Container fluid className='inside-page-2'>
              <Row>
                <Carousel indicators={false} interval={700} >
                  {application.company_sliders.map((item,index) => {
                    return (
                      <Carousel.Item key={index}>
                        <img src={item.large_image_path + item.company_banner_image}  alt='banner-image' />
                      </Carousel.Item>
                    )
                  })}
                </Carousel>
              </Row>
              <Container fluid className='inside-page-3' >
                <Row className='mt-5 mb-5'>
                  <Col lg={12} >
                    <h4 className='mb-4 company-heading'>{application.company_details.company_name}</h4>
                    {parse(application.company_details.description)}
                    <a href={application.company_details.website_url} target='_blank' > <Button>For more information click here ....</Button></a>
                  </Col>
                </Row>
              </Container>
            </Container>
          }
        </Container>
      )}
    </>


  )
}

export default C1






































