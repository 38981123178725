import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';



function Navber() {
  const[menus,setMenus]= useState()
  useEffect(()=>{
    axios.get('https://admin.bstloffshore.com/api/getMenus')
    .then(response => setMenus(response.data.data))

  },[0])


  return (
    <>
  <Navbar  expand="lg" sticky='top'>
      <Container>
      <div className='navbar-brand' >
  <Link to={'/'}>
    <img
      src='/Asets/logo/new-logo/MicrosoftTeams-image__50_-removebg-preview.png'
      alt='Logo'
      height={'auto'}
      width={'175px'}
      className='m-1'
  
    />
  </Link>
</div>

        <Navbar.Toggle aria-controls="basic-navbar-nav"  className='nav-lefts' />
        <Navbar.Collapse id="basic-navbar-nav-1">
         
            {menus && menus.map((item,index)=>{return(
            <Nav className="nav-menu " key={index}>

            <Link className='links'  to={item.slug}>{item.menu_name}</Link>
            
          </Nav>
          )})}
        </Navbar.Collapse>
      </Container>
    </Navbar>
      
      

      
    </>
  )
}

export default Navber
