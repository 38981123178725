import React from 'react'
import Navber from './Components/Main-Components/Navbar'
import Homepage from './Components/Main-Components/Homepage'
import Footer from './Components/Main-Components/Footer'
import Contact from './Components/Contact'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import About from './Components/About'
import Achievements from './Components/Main-Components/Achievements';
import Career from './Components/Career'
import Companies from './Components/Companies/Companies';
import Contry2 from './Components/Companies/Contry2';
import Apllication from './Components/Main-Components/Apllication';
import Dynamic_page from './Components/Main-Components/Dyanamic_pages'
import Scrolltop from './Components/Scrolltop';
import Gallary from './Components/Main-Components/Gallary'
import Subcomponent from './Components/Companies/Subcomponent'
import Term from './Components/Term'
import Privacy from './Components/Privacy'
function App() {

  return (
    <div>
      <BrowserRouter>
        <Navber/>
<Scrolltop/>
          <Routes>
            <Route path='/' element={<Homepage/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path="/page/:pagename" element={<Dynamic_page/>}/>
            <Route path='/about-us'   element={<About/>}/>
            <Route path='/achievements' element={<Achievements/>}/>
            <Route path='/career'       element={<Career/>}/>
            <Route path='/oman'         element={<Contry2/>}/>
            <Route path='/our-business'    element={<Companies/>}/>
            <Route path='/our-business/:company'     element={<Subcomponent/>}/>
            <Route path='/career/:job'  element={<Apllication/>}/>
            <Route path='/gallary'       element={<Gallary />}/>
            <Route path="/term-and-conditions"        element={<Term/>}/>
            <Route path="/privacy-policy"     element={<Privacy/>}/>
          </Routes>
        <Footer/>
        </BrowserRouter>

    </div>
  )
}

export default App
